import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { formatDateTime } from "../../Shared/Functions/Format";
import api from "../../Shared/Functions/APIHelpers";
import ConfirmAlert from "../../Shared/Components/Confirm/ConfirmAlert";
import { Box } from "@material-ui/core";
import PageHeader from "../../Shared/Nav/PageHeader";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormViewerContainer from "../../Shared/Components/FormViewer/FormViewerContainer";
import FormViewer from "../../Shared/Components/FormViewer/FormViewer";
import ContinuationLogHistoryTable from "../../Shared/Components/Continuation/ContinuationLogHistoryTable";
import SignatureModal from "../../Shared/Components/Signature/SignatureModal";
import EditIcon from "@material-ui/icons/Edit";
import config from "../../config";
import VisibilityIcon from "@material-ui/icons/Visibility";
// Box for continuation status change message
const ChangeContinuationStatusCard = ({ currentStatus }) => {
  const getHeaderText = () => {
    const fromText = currentStatus === 0 ? "State Continuation" : "COBRA";
    const toText = currentStatus === 0 ? "COBRA" : "State Continuation";

    return (
      <span>
        I want to change my continuation status from <strong>{fromText}</strong>{" "}
        To <strong>{toText}</strong>. This can only be changed once per year.
      </span>
    );
  };

  return (
    <Card style={{ marginBottom: 50, marginTop: 25 }}>
      <CardHeader
        subheader={getHeaderText()}
        style={{ backgroundColor: "#FFCC00" }}
      />
    </Card>
  );
};
// Card for siognature collection
const SignatureCard = ({ portalID, afterSignature }) => {
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  console.log("FROM BEFORE");
  console.log(portalID);
  return (
    <Box>
      <SignatureModal
        isOpen={signatureModalOpen}
        Close={() => {
          console.log("TRYING TO CLOSE");
          setSignatureModalOpen(false);
        }}
        attestationSignature={true}
        Public={true}
        ID={portalID}
        PortalID={portalID}
        UpdateSignature={afterSignature}
      />
      <Card style={{ marginTop: 20 }}>
        <CardHeader
          subheader="Signature Instructions"
          style={{ backgroundColor: "#f2f2f2" }}
        />
        <CardContent>
          <Typography variant="body1" style={{ textAlign: "left" }}>
            Please affirm that the information on the previous pages as well as
            the forms you have reviewed are complete and correct to the best of
            your knowledge by drawing or typing your signature.
          </Typography>
          <div style={{ clear: "both", height: 12 }} />
          <div style={{ marginTop: 30, marginBottom: 50, textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSignatureModalOpen(true);
              }}
            >
              <EditIcon />
              <div>Sign Form</div>
            </Button>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

const ContinuationStatusContainer = (props) => {
  const [portalID, setPortalID] = useState("");
  const [continuationAllowed, setContinuationAllowed] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [lastChange, setLastChange] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [step, setStep] = useState("info");

  /* Form state */
  const [groupName, setGroupName] = useState("");
  const [ein, setEIN] = useState("");
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState([]);
  const [contactFirst, setContactFirst] = useState("");
  const [contactLast, setContactLast] = useState("");
  const [logHistory, setLogHistory] = useState([]);
  const [hasSignedAttestation, setHasSignedAttestation] = useState(false);

  /*Form Viewer State */
  const [attestationPresignedURL, setAttestationPresignedURL] = useState("");

  /*state for confirm alert */
  const [confirmAlertTitle, setConfirmAlertTitle] = useState("");
  const [confirmAlertText, setConfirmAlertText] = useState("");
  const [confirmAlertOpen, setConfirmAlertOpen] = useState(false);
  const [doFunc, setDoFunc] = useState(null);
  const [doNotFunc, setDoNotFunc] = useState(null);

  useEffect(() => {
    setPortalID(getPortalID());
    handleGetContinuationAllowed();
  }, []);

  const handleGetContinuationAllowed = async () => {
    try {
      const r = await getContinuationAllowed();
      setContinuationAllowed(r.continuation_allowed);
      setCurrentStatus(r.current_status);
      setLastChange(r.last_change);
      setGroupName(r.group_name);
      setContactFirst(r.contact_first);
      setContactLast(r.contact_last);
      setEIN(r.group_number);
      setLogHistory(r.log_history);

      setLoaded(true);
    } catch (err) {
      console.log(err);
      this.props.history.push("/employer/auth?error=5");
    }
  };

  const getContinuationAllowed = () => {
    return props.employerIsSecured
      ? api.getContinuationAllowedSecured(getPortalID(), getConfirmationID())
      : api.getContinuationAllowed(getPortalID(), getConfirmationID());
  };

  const handleMapSignedContinuationForm = async () => {
    try {
      const r = await getMappedSignedContinuationForm();
      console.log("FINISHED MAPPING STORED");
    } catch (err) {
      console.log(err);
      this.props.history.push("/employer/auth?error=5");
    }
  };

  const getMappedSignedContinuationForm = () => {
    var body = JSON.stringify({ title: title });
    return props.employerIsSecured
      ? api.mapSignedContinuationAttestationSecured(getPortalID(), body)
      : api.mapSignedContinuationAttestation(getPortalID(), body);
  };

  const getConfirmationID = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get("confirmationID");
  };

  const getPortalID = () => {
    const params = new URLSearchParams(window.location.search);
    const portalID = params.get("portalID");
    return portalID ? portalID.replace(/-/g, "") : null;
  };

  const handleGoDocuments = () => {
    window.location.href =
      config.appLocation.URL +
      "/employer/documents?portalID=" +
      portalID +
      "&documentType=8";
  };

  const getHeaderProps = () => {
    if (step == "info") {
      return {
        hasBack: false,
        hasNext: true,
        pageTitle: "Continuation Status",
        backText: "back",
        nextText: "Change Status",
        back: null,
        next: () => {
          setStep("makeChange");
        },
      };
    }

    if (step == "makeChange") {
      return {
        hasBack: true,
        hasNext: true,
        pageTitle: "Change Continuation Status",
        backText: "Back",
        nextText: "View Confirmation",
        back: () => {
          setStep("info");
        },
        next: () => {
          tryMapForm();
        },
      };
    }

    if (step == "viewPDF") {
      return {
        hasBack: true,
        hasNext: true,
        pageTitle: "View Attestation Form",
        backText: "Back",
        nextText: "Sign",
        back: () => {
          setStep("makeChange");
        },
        next: () => {
          setStep("signPDF");
        },
      };
    }

    if (step == "signPDF") {
      return {
        hasBack: true,
        hasNext: false,
        pageTitle: "Sign Attestation Form",
        backText: "View Form",
        nextText: "",
        back: () => {
          setStep("viewPDF");
        },
        next: () => {
          console.log("done");
        },
      };
    }
  };

  const tryMapForm = () => {
    if (title == "") {
      setErrors(["title"]);
    } else {
      setStep("viewPDF");
    }
  };

  const InfoBlock = ({ label, value, variant = "body2", id }) => (
    <Typography variant={variant} id={id}>
      {label}: {value || ""}
    </Typography>
  );

  const { classes } = props;

  return (
    <>
      <ConfirmAlert
        isOpen={confirmAlertOpen}
        isNo={doNotFunc}
        isYes={doFunc}
        AlertTitle={confirmAlertTitle}
        AlertText={confirmAlertText}
      />

      {loaded && (
        <>
          {!props.navOptions.hasContinuation && continuationAllowed && (
            <>
              <Box style={{ color: props.theme.header_text_color }}>
                <Typography variant="h4" id="continuationStatusTitle">
                  Continuation Status
                </Typography>
              </Box>
              <Box style={{ marginTop: 40, maxWidth: 700 }}>
                <Typography variant="h5" id="notEnabledBody">
                  This feature is not currently enabled
                </Typography>
              </Box>
            </>
          )}
          {props.navOptions.hasContinuation && !continuationAllowed && (
            <>
              <Box style={{ color: props.theme.header_text_color }}>
                <Typography variant="h4" id="continuationStatusTitle">
                  Continuation Status
                </Typography>
              </Box>
              <Box
                style={{
                  marginLeft: -7,
                  marginTop: 40,
                  marginBottom: 30,
                  maxWidth: 500,
                }}
              >
                <Typography
                  variant="v5"
                  id="continuationWarning"
                  style={{ marginTop: 20 }}
                >
                  Continuation status cannot be changed more than one time per
                  calendar plan year
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoDocuments}
              >
                <VisibilityIcon />
                <Typography>View Confirmation Forms</Typography>
              </Button>
              <Box
                style={{
                  marginTop: 45,
                  marginBottom: 30,
                  color: props.theme.header_text_color,
                }}
              >
                <Typography variant="h5" id="continuationStatusTitle">
                  Status Change History
                </Typography>
              </Box>
              <Box>
                <ContinuationLogHistoryTable logHistory={logHistory} />
              </Box>
            </>
          )}

          {props.navOptions.hasContinuation && continuationAllowed && (
            <PageHeader
              hasBack={getHeaderProps()["hasBack"]}
              hasNext={getHeaderProps()["hasNext"]}
              pageTitle={getHeaderProps()["pageTitle"]}
              backText={getHeaderProps()["backText"]}
              nextText={getHeaderProps()["nextText"]}
              back={getHeaderProps()["back"]}
              next={getHeaderProps()["next"]}
            />
          )}
          {props.navOptions.hasContinuation &&
            continuationAllowed &&
            step == "info" && (
              <>
                <Box style={{ marginTop: 40, maxWidth: 700 }}>
                  <Typography variant="body2" id="statusBody">
                    Continuation status determines whether continuation of
                    coverage options will be based on State Continuation or
                    COBRA rules. If an employer has 20 or more employees during
                    a calendar year, they must offer COBRA health insurance
                    continuation starting January 1 of the following year. If an
                    employer currently subject to COBRA drops below 20
                    employees, they must continue to offer COBRA coverage
                    through the end of that year. This status may only be
                    changed one time during each calendar plan year
                  </Typography>
                </Box>
                <Box sx={{ marginTop: 20, maxWidth: 700 }}>
                  <Typography variant="body2" id="currentStatus">
                    Current Status
                  </Typography>
                  <Typography variant="h5" id="currentStatus">
                    {currentStatus ? "COBRA" : "State Continuation"}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: 5, maxWidth: 700 }}>
                  <InfoBlock
                    label="Last Change"
                    value={lastChange ? formatDateTime(lastChange) : ""}
                    id="lastChangeText"
                  />
                </Box>
              </>
            )}

          {props.navOptions.hasContinuation &&
            continuationAllowed &&
            step == "makeChange" && (
              <>
                <Box sx={{ width: 500 }}>
                  <ChangeContinuationStatusCard currentStatus={currentStatus} />
                  <TextField
                    id="group-name-text-field"
                    label="Group Name"
                    margin="dense"
                    value={groupName}
                    fullWidth
                    disabled
                  />
                  <TextField
                    id="group-number-text-field"
                    label="EIN"
                    margin="dense"
                    value={ein}
                    fullWidth
                    disabled
                  />

                  <FormLabel component="legend" style={{ marginTop: 70 }}>
                    Employer Contact
                  </FormLabel>

                  <TextField
                    id="employerContactFirstTextField"
                    label="First"
                    margin="dense"
                    value={contactFirst}
                    fullWidth
                    disabled
                  />

                  <TextField
                    id="employerContactLastTextField"
                    label="Last"
                    margin="dense"
                    value={contactLast}
                    fullWidth
                    disabled
                  />

                  <TextField
                    id="title-text-field"
                    label="Title"
                    margin="dense"
                    error={errors.includes("title")}
                    value={title}
                    fullWidth
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Box>
              </>
            )}
          {props.navOptions.hasContinuation &&
            continuationAllowed &&
            step == "viewPDF" && (
              <>
                <FormViewerContainer
                  displaySidebar={false}
                  setGoNext={() => {
                    console.log("NExt");
                  }}
                  setGoBack={() => {
                    console.log("BACK");
                  }}
                  setFirstForm={() => {
                    console.log("first form");
                  }}
                  setLastForm={() => {
                    console.log("last form");
                  }}
                  isDoneLoading={() => {
                    console.log("done");
                  }}
                  attestationFormData={{
                    body: JSON.stringify({ title: title }),
                    portalID: getPortalID(),
                    employerIsSecured: props.employerIsSecured,
                  }}
                />
              </>
            )}
          {props.navOptions.hasContinuation &&
            continuationAllowed &&
            step == "signPDF" && (
              <>
                <Box>
                  <SignatureCard
                    portalID={portalID}
                    afterSignature={handleMapSignedContinuationForm}
                  />
                </Box>
              </>
            )}
        </>
      )}
    </>
  );
};
export default ContinuationStatusContainer;
