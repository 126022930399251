import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExitIcon from "@material-ui/icons/ExitToApp";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SignatureSuccess from "./SignatureSuccess";
import config from "../../../config";
import Signature from "./Signature.js";
import "./Styles/checkmark.css";
import { Auth } from "aws-amplify";

const styles = (theme) => ({
  title: {
    "@media only screen and (max-height: 400px)": {
      display: "none",
    },
  },
  content: {
    height: 420,
    minWidth: 700,
    "@media only screen and (max-width: 400px)": {
      minWidth: 0,
    },
  },
  saving: {
    marginTop: 90,
    width: 700,
    textAlign: "center",
    "@media only screen and (max-width: 400px)": {
      width: "100%",
    },
  },
  sigDiv: {
    display: "flex",
    justifyContent: "center",
    "@media only screen and (max-width: 800px)": {
      justifyContent: "left",
    },
  },
  textField: {
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
    "@media only screen and (max-height: 400px)": {
      display: "none",
    },
  },
  instructions: {
    //marginTop:10,
    "@media only screen and (max-height: 400px)": {
      display: "none",
    },
  },
  notification: {
    fontSize: 12,
    //color: 'blue',
  },
  sigFooterDiv: {
    textAlign: "center",
    "@media only screen and (max-width: 910px)": {
      textAlign: "center",
    },
  },
  checkDone: {
    height: 300,
    minWidth: 700,
    marginTop: 10,
    "@media only screen and (max-width: 600px)": {
      minWidth: 0,
      width: "100%",
    },
    "@media only screen and (max-height: 400px)": {
      marginTop: 0,
      height: 280,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

export class SignatureModal extends React.Component {
  state = {
    showSuccess: false,
    sigDone: false,
    //sigSuccess: false
  };

  signatureIsSaved = () => {
    console.log("signature is saved");
  };

  handleClose = () => {
    this.setState({ showSuccess: false });
    this.props.Close();
  };

  /*
  updateSignature = MedicareID => {
    return new Promise((resolve, reject) => {
      api
        .updateMedicareSignature(this.props.MedicareID)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          this.setState({ errorMessage: "Error Updating Signature", sigDone: true });
          console.log(err);
        });
    });
  };
  */
  afterStorage = () => {
    return this.props.UpdateSignature(this.props.ID);
  };

  handleGoDashboard = () => {
    window.location.href =
      config.appLocation.URL + "/employer/home?portalID=" + this.props.PortalID;
  };

  render() {
    const { classes } = this.props;
    const { fullScreen } = this.props;
    console.log("LOOKING FOR JOES PROP");
    console.log(this.props.attestationSignature);
    return (
      <Dialog
        open={this.props.isOpen}
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
        scroll="paper"
        maxWidth={"lg"}
        onClose={this.props.Close}
        //style={{maxWidth:1500}}
      >
        <DialogTitle id="form-dialog-title" className={classes.title}>
          Signature
        </DialogTitle>

        <DialogContent className={classes.content}>
          <Signature
            IsDone={() => {
              this.setState({ showSuccess: true });
            }}
            //isOpen={this.state.signatureModalOpen}
            //Close={this.handleClose}
            Public={true}
            ID={this.props.ID}
            attestationSignature={this.props.attestationSignature}
            AfterStorage={this.afterStorage}
          />

          {this.state.showSuccess && this.props.DoneMark === undefined && (
            <React.Fragment>
              <SignatureSuccess />
            </React.Fragment>
          )}
          {this.state.showSuccess && this.props.DoneMark !== undefined && (
            <React.Fragment>{this.props.DoneMark}</React.Fragment>
          )}
        </DialogContent>

        <DialogActions>
          {this.state.showSuccess && this.props.CloseButton === undefined && (
            <Button
              style={{ marginLeft: -17 }}
              onClick={this.handleGoDashboard}
              color="primary"
            >
              Go To Dashboard
            </Button>
          )}
          {this.state.showSuccess && this.props.CloseButton !== undefined && (
            <React.Fragment>{this.props.CloseButton}</React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(SignatureModal));

//export { Signature as SignatureNoRedux};
