import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import api from "../../Shared/Functions/APIHelpers";
import ContinuationLogHistoryTable from "../../Shared/Components/Continuation/ContinuationLogHistoryTable";
export default function EmployerContinuationHistoryDialog({
  fullScreen,
  Open,
  Close,
  Employer,
}) {
  const [logHistory, setLogHistory] = useState([]);
  useEffect(() => {
    handleGetEmployerContinuationStatusLog(Employer.portal_id);
  }, []);

  const handleGetEmployerContinuationStatusLog = async (portalID) => {
    try {
      const r = await api.getMEWAEmployerContinuationStatusLog(portalID);
      setLogHistory(r);
      //setLoaded(true);
    } catch (err) {
      console.log(err);
      this.props.history.push("/employer/auth?error=5");
    }
  };
  return (
    <Dialog
      open={!!Open}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">{Employer.name}</DialogTitle>

      <DialogContent style={{ minHeight: 400, minWidth: 325, maxWidth: 450 }}>
        <Typography style={{ marginBottom: 30 }} variant="body1">
          Continuation History
        </Typography>
        <ContinuationLogHistoryTable logHistory={logHistory} />
      </DialogContent>
      <DialogActions>
        <Button onClick={Close} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
