import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import sig_styles from "./Styles/sig_styles.cssm";
import SignaturePad from "./signature_pad.js";
import { Buffer } from "buffer";
import "./Styles/checkmark.css";
import { Storage } from "aws-amplify";

const styles = (theme) => ({
  title: {
    "@media only screen and (max-height: 400px)": {
      display: "none",
    },
  },
  content: {
    height: 450,
  },
  saving: {
    marginTop: 90,
    width: 700,
    textAlign: "center",
    "@media only screen and (max-width: 400px)": {
      width: "100%",
    },
  },
  sigDiv: {
    display: "flex",
    justifyContent: "center",
    "@media only screen and (max-width: 800px)": {
      justifyContent: "left",
    },
  },
  textField: {
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
    "@media only screen and (max-height: 400px)": {
      display: "none",
    },
  },
  instructions: {
    //marginTop:10,
    "@media only screen and (max-height: 400px)": {
      display: "none",
    },
  },
  notification: {
    fontSize: 12,
    //color: 'blue',
  },
  sigFooterDiv: {
    textAlign: "center",
    "@media only screen and (max-width: 910px)": {
      textAlign: "center",
    },
  },
  checkDone: {
    height: 300,
    minWidth: 700,
    marginTop: 10,
    "@media only screen and (max-width: 600px)": {
      minWidth: 0,
      width: "100%",
    },
    "@media only screen and (max-height: 400px)": {
      marginTop: 0,
      height: 280,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

export class Signature extends React.Component {
  state = {
    trimmedDataURL: null,
    name: "",
    ip: "",
    alert: false,
    sigWidth: 700,
    canvasProps: { height: 250, width: 700, clearonresize: "false" },
    sigDone: false,
    sigSuccess: false,
    sigError: false,
    sigErrorMessage: "",
  };

  updateDimensions = () => {
    var w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName("body")[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height =
        w.innerHeight || documentElement.clientHeight || body.clientHeight;

    this.setState({ width, height });

    if (width > 900) {
      this.setState({
        canvasProps: { clearonresize: "false", height: 250, width: 700 },
      });
    } else if (width > 400) {
      this.setState({
        canvasProps: {
          clearonresize: "false",
          height: 250,
          width: width - 150,
        },
      });
    } else {
      this.setState({
        canvasProps: { clearonresize: "false", height: 250, width: width - 50 },
      });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();

    //get external IP for stamp
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => this.setState({ ip: data.ip }));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  getFooterStampPrivate = (time) => {
    //return " - " + time + " from " + this.state.ip + " - mewa.io";
    return time + " from " + this.state.ip + " - mewa.io";
  };

  getFooterStampPublic = (time) => {
    //return " - " + time + " from " + this.state.ip + " - mewa.io";
    return time + " from " + this.state.ip + " - mewa.io";
  };

  storeImageFilePrivate = (buf) => {
    console.log("bucket");
    console.log(process.env.NODE_ENV);
    console.log(process.env.REACT_APP_FILE_BUCKET);
    console.log(process.env.REACT_APP_GATEWAY_URL);
    //console.log(config.s3)
    Storage.configure({ level: "private" });

    Storage.put("sig_versioned.png", buf, {
      level: "private",
      contentType: "img/png",
    })
      .then((result) => {
        this.props.AfterStorage().then((r) => {
          this.setState({ sigSuccess: true });
          this.props.IsDone();
        });
      })
      .catch((err) => console.log(err));
  };

  storeImageFilePublic = (buf) => {
    //Auth configuration

    /*
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-2:0f522ead-685f-4b56-b4e1-dbc4f988201c'
    });

    const anonymousUser = Auth.currentCredentials()

    console.log(anonymousUser)
    */

    console.log("v100");

    Storage.configure({ level: "public" });
    /*
    console.log(this.props.Presigned.fields);
    const formData = new FormData();
    formData.append("key",this.props.Presigned.fields.key);
    formData.append("file", file);
    formData.append("AWSAccessKeyId", this.props.Presigned.fields.AWSAccessKeyId);
    formData.append("policy", this.props.Presigned.fields.policy);
    formData.append("signature", this.props.Presigned.fields.signature);
    */

    var fileLocation = this.props.ID + "/signatures/" + this.props.ID + ".png";
    if (this.props.attestationSignature) {
      console.log("IM RIGHT HERE");
      console.log("\n\n\n\n\n\n\n");
      console.log(this.props.ID);
      fileLocation = this.props.ID + "/attestation/signature.png";
    }
    console.log(fileLocation);
    console.log(process.env.REACT_APP_BUCKET);
    Storage.put(fileLocation, buf)
      .then((result) => {
        console.log(result);
        this.props.AfterStorage().then(() => {
          this.setState({ sigSuccess: true });
          this.props.IsDone();
        });
      })
      .catch((err) => {
        console.log("OMG IM HERE");
        this.setState({ sigError: true });
        this.setState({ sigErrorMessage: err });
        console.log(err);
      });
  };

  addStamp = () => {
    var canvas = this.refs.canvas_stamped;
    var ctx = canvas.getContext("2d");

    var time = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(Date.now());
    if (this.props.Public) var footerStamp = this.getFooterStampPublic(time);
    else footerStamp = this.getFooterStampPrivate(time);

    var img_temp = document.createElement("img");
    img_temp.setAttribute("src", this.state.trimmedDataURL);

    img_temp.onload = () => {
      //ctx.scale(1/dpr, 1/dpr)
      ctx.drawImage(img_temp, 0, 25);

      var baseFontSize = 16;
      var fontSize = baseFontSize;
      ctx.font = String(fontSize) + "px sans-serif";
      ctx.textBaseline = "top";
      ctx.fillStyle = "#fff";

      var width = ctx.measureText(footerStamp).width;
      var height = parseInt(ctx.font, 8);

      ctx.fillRect(5, canvas.height - 20, width, height);

      //set stamp font color
      ctx.fillStyle = "#808080";
      ctx.fillText(footerStamp, 5, canvas.height - 20);

      var img = this.refs.canvas_stamped.toDataURL("image/png");

      var buf = new Buffer(
        img.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );
      if (this.props.Public) this.storeImageFilePublic(buf);
      else this.storeImageFilePrivate(buf);
    };
  };

  //should we ever wish to display the signature...
  /*
  load = () => {

    Storage.get('sig_versioned.png', {level: 'private'})
      .then(result => {
        console.log(result)
        this.setState({trimmedDataURL: result})
        var canvas = this.refs.canvas_stamped
        var ctx = canvas.getContext('2d')

        var img_temp = document.createElement("img")
        img_temp.setAttribute("src", result)
        img_temp.onload = () => {
          ctx.drawImage(img_temp, 0, 0)
        }
      })
      .catch(err => console.log(err));
  }
  */

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });

    var canvas = this.sigPad.getCanvas();
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    //set font type
    console.log("SET FONT");
    ctx.font = "70px 'La Belle Aurore', cursive";
    ctx.textBaseline = "top";

    //set font color
    ctx.fillStyle = "#000";
    ctx.fillText(event.target.value, 0, 90);
  };

  handleSave = () => {
    var img = this.sigPad.getCanvas().toDataURL("image/png");
    var trimmed = this.sigPad.getTrimmedCanvas().toDataURL("image/png");

    var img_temp = document.createElement("img");

    img_temp.setAttribute("src", trimmed);
    setTimeout(
      function () {
        var height = img_temp.height;
        var width = img_temp.width;

        if (!(height > 70 || width > 70)) {
          this.setState({ alert: true });
        } else {
          this.setState(
            {
              trimmedDataURL: img,
            },
            () => {
              this.setState({ sigDone: true });
              this.addStamp(); //will call storage save
              //call prop for what happens when done
            }
          );
        }
      }.bind(this),
      0
    );
  };

  handleClearSignature = () => {
    this.setState({
      name: "",
      alert: false,
    });

    if (this.state.trimmedDataURL) {
      this.setState({ trimmedDataURL: null });
    } else {
      this.sigPad.clear();
    }
  };

  render() {
    let { trimmedDataURL } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div style={{ display: "none" }}>
          {this.state.sigDone && "sigDone: TRUE"}
          <br />
          {!this.state.sigDone && "sigDone: FALSE"}
          <br />
          {this.state.sigSuccess && "sigSuccess: TRUE"}
          <br />
          {!this.state.sigSuccess && "sigSuccess: FALSE"}
          <br />
          {this.state.sigError && "sigError: TRUE"}
          <br />
          {this.state.sigError && (
            <div>ERROR MESSAGE: {this.state.sigErrorMessage}</div>
          )}
          {!this.state.sigError && "sigError: FALSE"}
          <br />
        </div>
        <div className={classes.sigDiv} id="sig-div">
          <div>
            <div style={{ display: this.state.sigDone ? "none" : "block" }}>
              <div className={classes.textField}>
                <Typography
                  variant="subtitle2"
                  className={classes.instructions}
                >
                  Draw or type your signature to sign this form.
                </Typography>
                <TextField
                  label="Full Name"
                  margin="dense"
                  onChange={this.handleNameChange}
                  value={this.state.name}
                  id="sign-form-text-box"
                  autoComplete="off"
                  fullWidth
                />
              </div>
            </div>

            {this.state.name && !this.state.sigDone ? (
              <Typography
                color="primary"
                className={classes.notification}
                gutterBottom
              >
                You may still draw over your signature!
              </Typography>
            ) : null}

            <div
              className={sig_styles.sigContainer}
              style={{
                height: 250,
                marginTop: 10,
                borderTop: "0px solid #d9d9d9",
                borderBottom: "11q2px solid #d9d9d9",
                marginBottom: 25,
                display: this.state.sigDone ? "none" : "block",
              }}
            >
              <SignaturePad
                canvasProps={this.state.canvasProps}
                ref={(ref) => {
                  this.sigPad = ref;
                }}
              />
              <div className={classes.sigFooterDiv}>
                {this.state.alert ? (
                  <Typography variant="caption" color="error" gutterBottom>
                    Please make your signature a little larger!
                  </Typography>
                ) : null}
              </div>
            </div>

            <div style={{ display: "none" }}>
              <canvas ref="canvas_stamped" width={700} height={300} />
            </div>

            <div
              style={{
                width: "100%",
                textAlign: "right",
                display: this.state.sigDone ? "none" : "inline-block",
              }}
            >
              <Button
                variant="contained"
                color="default"
                style={{ width: 150, marginLeft: 0 }}
                onClick={this.handleClearSignature}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ width: 150, marginLeft: 20 }}
                onClick={this.handleSave}
                id="s3-save-sign-button"
              >
                Sign
              </Button>
            </div>
            {trimmedDataURL ? (
              <img
                ref="sig_image"
                alt="sig_image"
                className={sig_styles.sigImage}
                src={trimmedDataURL}
                style={{ display: "none" }}
              />
            ) : null}
          </div>

          {this.state.sigDone && !this.state.sigSuccess && (
            <React.Fragment>
              <div className={classes.saving}>
                <CircularProgress />
                <Typography variant="body1">Saving...</Typography>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(Signature));

//export { Signature as SignatureNoRedux};
